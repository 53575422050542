import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Image, Row, Tooltip, notification } from 'antd';
import emailjs from 'emailjs-com';
import { useCollection, useCollectionActions } from '../../context/collections';
import { CarouselContainer, CollectionDrawer, ContentWrapper, DrawerBody, DrawerFooter, DrawerHeader, DrawerWrapper, InfoContainer, InputNumberContainer, MaskInfo, MasonryLayout, MobileContainer, MobileDeleteBtnContainer, MobileHeader, MobileProductImage, PricingTable, ProductDetails, ProductImage, ProductImageContainer, ProductImageMobile, ProductImageOverlay, ProductsCategoryContainer, ProductsTitle, ProductsWrapper, StyledCarousel, TotalPrice } from './style';
import { ProductType } from '../../types/type';
// import ViewImageInfo from '../../components/icons/ViewImageInfo';
import HideImageInfo from '../../components/icons/HideImageInfo';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import RequestModal from '../../components/Request/RequestModal';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../../constants';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import { DeleteFilled, InfoCircleOutlined } from '@ant-design/icons';
import Spinner from '../../components/common/Spinner';
// import { getRandomSize } from '../../helpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatPrice, transformToHyphenated } from '../../helpers';
import { SlickArrowLeft, SlickArrowRight } from '../../components/Buttons/CarouselButtons';
import CustomInputNumber from '../../components/Input/CustomInputNumber';

function ProdImage({ prod, handleVisitProduct, selectedLanguage }: any) {
  const [isLoading, setIsLoading] = useState(true);
  // const { height } = getRandomSize(0, 0, 200, 400);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = (e: any) => {
    setIsLoading(false);
    e.target.src = DEFAULT_IMAGE_PLACEHOLDER;
  };

  return (
    <>
      {isLoading && <Spinner size="large" />}
      <ProductImage
        src={prod?.url || prod?.highres || DEFAULT_IMAGE_PLACEHOLDER}
        // src={prod.img}
        // style={{ height }}
        onLoad={handleLoad}
        onClick={handleVisitProduct}
        onError={handleError}
        preview={{
          mask: (
            <MaskInfo>
              <h3>
                {prod?.name ||
                  (selectedLanguage === 'ES'
                    ? prod?.technicalDescriptionEs || prod?.technicalDescription
                    : prod?.technicalDescription)
                }
              </h3>
              <p>
                {prod?.subtitle ?? prod?.bANQADescriptionENG}
              </p>
            </MaskInfo>
          )
        }}
      />
    </>
  );
}

function MobileCarouselProdImage({ prod, handleVisitProduct, onRemoveCollection }: any) {
  const [isLoading, setIsLoading] = useState(true);
  // const { height } = getRandomSize(0, 0, 200, 400);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = (e: any) => {
    setIsLoading(false);
    e.target.src = DEFAULT_IMAGE_PLACEHOLDER;
  };

  return (
    <MobileProductImage>
      {isLoading && <Spinner size="large" />}
      <MobileDeleteBtnContainer>
        <SecondaryButton onClick={onRemoveCollection} shape='circle' icon={<DeleteFilled />} />
      </MobileDeleteBtnContainer>
      <div className="image-container">
        <ProductImageMobile
          src={prod?.url || prod?.highres || DEFAULT_IMAGE_PLACEHOLDER}
          // src={prod.img}
          // style={{ height }}
          onLoad={handleLoad}
          onClick={handleVisitProduct}
          onError={handleError}
        />
      </div>
    </MobileProductImage>
  );
}

const Collections = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { state } = useCollection();
  const { t, i18n } = useTranslation();
  const { removeCollection, resetCollection, updateQuantity } = useCollectionActions();
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [imageData, setImageData] = useState<ProductType[]>(state.items);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const collectionImagesRef = useRef(null);

  const selectedLanguage = useMemo(() => {
    return i18n.language !== 'gb' ? 'ES' : 'GB';
  }, [i18n.language]);

  const totalPrice = useMemo(() => {
    // Calculate the base total price from the items and quantities
    const baseTotalPrice = state.items.reduce((acc: number, value: any) => {
      const price = value?.price?.replace('.', '')?.replace(',', '.');
      const quantity = value?.quantity || 1; // Default to 1 if quantity is undefined
      return acc + (parseFloat(price) || 0) * quantity;
    }, 0);

    // Determine if discount applies (only if 2 or more products)
    const totalItems = state.items.reduce((acc: number, value: any) => acc + (value?.quantity || 1), 0);
    let discount = 0;

    if (totalItems >= 2) {
      // Apply progressive discount based on scales
      if (baseTotalPrice > 20000) {
        discount += (baseTotalPrice - 20000) * 0.2; // Scale 3 (20%)
      }
      if (baseTotalPrice > 10000) {
        discount += (Math.min(baseTotalPrice, 20000) - 10000) * 0.15; // Scale 2 (15%)
      }
      discount += Math.min(baseTotalPrice, 10000) * 0.1; // Scale 1 (10%)
    }

    // Apply discount after VAT
    const finalPrice = baseTotalPrice - discount;
    // Calculate VAT (21% of the discounted price)
    const vatAmount = (finalPrice / 121) * 21;
    return { baseTotalPrice, vatAmount, discount, finalPrice };
  }, [state.items]);

  const handleShowImageInfo = (id: number | string) => {
    setImageData((prev: any) =>
      prev.map((data: any) =>
        data.id === id ? { ...data, showInfo: !data.showInfo } : data
      )
    );
  };

  const handleVisitProduct = (technicalDescription: string) => {
    const transformedTechnicalDescription = transformToHyphenated(technicalDescription);
    navigate(`/products/${transformedTechnicalDescription}`);
  };

  const onProducts = () => {
    navigate('/shop');
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (values: any) => {
    setLoading(true);
    const collectionImages = imageData.reduce((acc: any, value: any, index: number) => {
      acc[`collection_${index + 1}`] = value.medium;
      return acc;
    }, {});

    const emailData: any = {
      fullName: values?.fullName,
      email: values?.email,
      phoneNumber: values?.phoneNumber,
      links: values?.links,
      totalPrice: values?.totalPrice,
      dateTime: values?.dateTime ? moment(values.dateTime).isValid() ? moment(values.dateTime).format('DD-MM-yyyy') : '' : '',
      description: values?.description,
      link_1: values?.link_1,
      link_2: values?.link_2,
      link_3: values?.link_3,
      link_4: values?.link_4,
      link_5: values?.link_5,
      link_6: values?.link_6,
      ...collectionImages
    };

    emailjs.send('service_4qnk66m', 'template_u4ota17', emailData, 'YvW3RqHC-ysKQb-aq')
      .then(() => {
        api.success({
          message: `Request sent successfully`,
          placement: 'top',
        });
        setEmailSent(true);
      }, (error) => {
        api.error({
          message: `Email send error: ${error.text}`,
          placement: 'top',
        });
      }).finally(() => {
        setLoading(false);
      });

    setModalVisible(false);
  };

  const handleCreateNewCollection = () => {
    resetCollection();
    setEmailSent(false);
  };

  const onRemoveCollection = (e: any, id: string) => {
    e.stopPropagation();
    removeCollection(id);
  };

  useEffect(() => {
    setImageData(state.items);
  }, [state.items]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <ContentWrapper ref={collectionImagesRef}>
        {contextHolder}
        <ProductsWrapper>
          <ProductsCategoryContainer>
            <ProductsTitle>{t('collection.title')}</ProductsTitle>
            <p>{t('collection.description_1')}</p>
            <p>{t('collection.description_2')}</p>
          </ProductsCategoryContainer>
          <MasonryLayout>
            {(imageData ?? []).map((pr: any, index: number) => (
              <ProductImageContainer key={pr.id} delay={++index}>
                <ProdImage
                  prod={pr}
                  onClick={() => handleVisitProduct(pr.technicalDescription as string)}
                  selectedLanguage={selectedLanguage}
                />
                <ProductImageOverlay show={pr.showInfo}>
                  <Row className="masked-row">
                    <Col sm={22}>
                      <MaskInfo>
                        <h3>{pr?.name || pr?.technicalDescription}</h3>
                        <p>{pr?.subtitle || pr?.bANQADescriptionENG}</p>
                      </MaskInfo>
                    </Col>
                    <Col sm={2}>
                      <Button
                        shape="circle"
                        icon={<HideImageInfo />}
                        onClick={() => handleShowImageInfo(pr.id)}
                      />
                    </Col>
                  </Row>
                </ProductImageOverlay>
              </ProductImageContainer>
            ))}
          </MasonryLayout>
        </ProductsWrapper>
        <CollectionDrawer height={dimensions.height}>
          <div>
            <DrawerHeader>
              <p>{t('collection.your_collection')}</p>
            </DrawerHeader>
            <DrawerWrapper>
              {state.items.map((pr: any) => (
                <DrawerBody onClick={() => handleVisitProduct(pr.technicalDescription as string)}>
                  <Col span={6}>
                    <Image
                      height={71}
                      src={pr.url || pr.medium || DEFAULT_IMAGE_PLACEHOLDER}
                      fallback={DEFAULT_IMAGE_PLACEHOLDER}
                    />
                  </Col>
                  <Col span={16}>
                    <ProductDetails>
                      <p>{pr.name || pr.technicalDescription}</p>
                      {/* <p>€ {pr.price}</p> */}
                    </ProductDetails>
                  </Col>
                  <Col span={2}>
                    <SecondaryButton onClick={(e: any) => onRemoveCollection(e, pr.id as string)} shape='circle' icon={<DeleteFilled />} />
                  </Col>
                  <InputNumberContainer>
                    <CustomInputNumber
                      size="small"
                      value={pr.quantity}
                      defaultValue={pr.quantity || 1}
                      onIncrement={() => updateQuantity(pr.id, 1, null)}
                      onDecrement={() => updateQuantity(pr.id, -1, null)}
                      onChange={(value: number | null) => {
                        if (value !== null) {
                          updateQuantity(pr.id, null, value);
                        }
                      }}
                    />
                  </InputNumberContainer>
                </DrawerBody>
              ))}
            </DrawerWrapper>
          </div>
          <DrawerFooter>
            <TotalPrice>
              <div>
                <PricingTable>
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td>{formatPrice(totalPrice.baseTotalPrice)}</td>
                    </tr>
                    <tr>
                      <td>Discount</td>
                      <td className="discount">
                        {formatPrice(totalPrice.discount)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <InfoContainer>
                          <p>
                            <b>{t('collection.total_price')}{' '}</b>
                          </p>
                          <Tooltip placement="topRight" title={
                            <>
                              <span>{t('collection.info_transport')}</span>
                              <p>{t('collection.discount_info')}</p>
                            </>
                          }>
                            <PrimaryButton type="text" icon={<InfoCircleOutlined />} />
                          </Tooltip>
                        </InfoContainer>
                      </td>
                      <td>
                        <p style={{ textAlign: 'right', fontSize: '1.25rem', margin: 0 }}>
                          {formatPrice(totalPrice.finalPrice)}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </PricingTable>
                <p className="vat">
                  <b>
                    {`(Incl. ${t('collection.VAT')}: ${formatPrice(totalPrice.vatAmount)})`}
                  </b>
                </p>
              </div>
            </TotalPrice>
            <PrimaryButton onClick={handleModalOpen} type="primary" disabled={emailSent}>
              {emailSent ? t('button.request_sent') : t('button.request_call')}
            </PrimaryButton>
            {emailSent && (
              <PrimaryButton onClick={handleCreateNewCollection} type="primary">
                {t('button.create_new_collection')}
              </PrimaryButton>
            )}
            <p onClick={onProducts}>{t('collection.continue_adding_collection')}</p>
          </DrawerFooter>
        </CollectionDrawer>
        <RequestModal
          visible={modalVisible}
          onCancel={handleModalCancel}
          onFinish={handleFormSubmit}
          loading={loading}
        />
      </ContentWrapper>
      <MobileContainer>
        <MobileHeader>
          <div>
            <h2>Your Collection</h2>
          </div>
          <div>
            <PrimaryButton onClick={handleModalOpen} type="primary" disabled={emailSent}>
              {emailSent ? t('button.request_sent') : t('button.request_call')}
            </PrimaryButton>
            <p onClick={onProducts}>{t('collection.continue_adding_collection')}</p>
          </div>
        </MobileHeader>
        <p>{t('collection.description_1')}</p>
        <p>{t('collection.description_2')}</p>
        <CarouselContainer>
          <StyledCarousel
            arrows
            className="desktop-carousel"
            prevArrow={<SlickArrowLeft currentSlide={0} slideCount={0} />}
            nextArrow={<SlickArrowRight currentSlide={0} slideCount={imageData?.length} />}
          >
            {(imageData ?? []).map((pr: any, index: number) => (
              <ProductImageContainer key={pr.id} delay={++index}>
                <InputNumberContainer>
                  <CustomInputNumber
                    size="small"
                    value={pr.quantity as number}
                    defaultValue={pr.quantity || 1}
                    onIncrement={() => updateQuantity(pr.id, 1, null)}
                    onDecrement={() => updateQuantity(pr.id, -1, null)}
                    onChange={(value: number | null) => {
                      if (value !== null) {
                        updateQuantity(pr.id, null, value);
                      }
                    }}
                  />
                </InputNumberContainer>
                <MobileCarouselProdImage
                  prod={pr}
                  onRemoveCollection={(e: any) => onRemoveCollection(e, pr?.id as string)}
                  onClick={() => handleVisitProduct(pr.technicalDescription as string)}
                  selectedLanguage={selectedLanguage}
                />
                <ProductImageOverlay show={pr.showInfo}>
                  <Row className="masked-row">
                    <Col sm={22}>
                      <MaskInfo>
                        <h3>{pr?.name || pr?.technicalDescription}</h3>
                        <p>{pr?.subtitle || pr?.bANQADescriptionENG}</p>
                      </MaskInfo>
                    </Col>
                    <Col sm={2}>
                      <Button
                        shape="circle"
                        icon={<HideImageInfo />}
                        onClick={() => handleShowImageInfo(pr.id)}
                      />
                    </Col>
                  </Row>
                </ProductImageOverlay>
              </ProductImageContainer>
            ))}
          </StyledCarousel>
        </CarouselContainer>
        <TotalPrice>
          <div>
            <PricingTable>
              <tbody>
                <tr>
                  <td>Sub Total</td>
                  <td>{formatPrice(totalPrice.baseTotalPrice)}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td className="discount">
                    {formatPrice(totalPrice.discount)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <InfoContainer>
                      <p>
                        <b>{t('collection.total_price')}{' '}</b>
                      </p>
                      <Tooltip placement="topRight" title={
                        <>
                          <span>{t('collection.info_transport')}</span>
                          <p>{t('collection.discount_info')}</p>
                        </>
                      }>
                        <PrimaryButton type="text" icon={<InfoCircleOutlined />} />
                      </Tooltip>
                    </InfoContainer>
                  </td>
                  <td>
                    <p style={{ textAlign: 'right', fontSize: '1.25rem', margin: 0 }}>
                      {formatPrice(totalPrice.finalPrice)}
                    </p>
                  </td>
                </tr>
              </tbody>
            </PricingTable>
            <p className="vat">
              <b>
                {`(Incl. ${t('collection.VAT')}: ${formatPrice(totalPrice.vatAmount)})`}
              </b>
            </p>
          </div>
        </TotalPrice>
        <div>
          {(imageData ?? []).map((pr: any, index: number) => (
            <ProductImageContainer key={pr.id} delay={++index}>
              <ProdImage
                prod={pr}
                onClick={() => handleVisitProduct(pr.technicalDescription as string)}
                selectedLanguage={selectedLanguage}
              />
              <ProductImageOverlay show={pr.showInfo}>
                <Row className="masked-row">
                  <Col sm={22}>
                    <MaskInfo>
                      <h3>{pr?.name || pr?.technicalDescription}</h3>
                      <p>{pr?.subtitle || pr?.bANQADescriptionENG}</p>
                    </MaskInfo>
                  </Col>
                  <Col sm={2}>
                    <Button
                      shape="circle"
                      icon={<HideImageInfo />}
                      onClick={() => handleShowImageInfo(pr.id)}
                    />
                  </Col>
                </Row>
              </ProductImageOverlay>
            </ProductImageContainer>
          ))}
        </div>
      </MobileContainer>
    </>
  )
}

export default Collections